import React from 'react'
import { Facebook, Linkedin } from 'react-feather'
import { Link } from 'gatsby'
import './Footer.scss'

export default () => (
  <div>
    <footer className="footer">
      <ul className="footer-links">
        <li><Link to="/">Home</Link></li>
        <li><Link to="/about/">About</Link></li>
        <li><Link to="/services/">Services</Link></li>
        <li><Link to="/delivery-areas/">DELIVERY AREAS</Link></li>
        <li><Link to="/news/">NEWS</Link></li>
        <li><Link to="/faq/">FAQ</Link></li>
        <li><Link to="/get-in-touch/">CONTACT US</Link></li>
      </ul>
      
      <div className="container">
        <ul className="social">
          <li><a href="http://www.facebook.com/Pelicanlightcarryingco/"><Facebook /></a></li>
          <li><a href="https://www.linkedin.com/company/pelican-light-carrying-co"><Linkedin /></a></li>
        </ul>
      </div>
      
      <div className="container taCenter">
        <span>
        © Copyright {new Date().getFullYear()} Pelican Carrying and Gosford Couriers. All rights reserved.
        </span>
        <span>
          Pelican are a logistics company offering delivery services on the Central Coast, NSW. All kinds of deliveries are quoted for - from house moves to transporting commercial goods. We have a good fleet of vehicles and plenty of experience. We offer transportation of palletised goods, furniture, electrical appliances &amp; more. We are able to transports goods to and from the <a href="/delivery-areas/central-coast/">Central Coast</a>, <a href="/delivery-areas/sydney/">Sydney</a> and <a href="/delivery-areas/newcastle/">Newcastle</a>. For the most affordable delivery service on the Central Coast look no further than Pelican Light Carrying Company.
        </span>
      </div>
    </footer>
  </div>
)

