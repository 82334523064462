import React, { Component, Fragment } from 'react'
import { Location } from '@reach/router'
import { Link } from 'gatsby'
import { Menu, X } from 'react-feather'


import Observer from './Observer'

import './Nav.scss'

export class Navigation extends Component {
  constructor(props) {
    super(props)
    this.ref = React.createRef()
  }

  state = {
    active: false,
    currentPath: false,
    reducedNavSize: false
  }

  componentDidMount = () =>
    this.setState({ currentPath: this.props.location.pathname })

  handleMenuToggle = () => this.setState({ active: !this.state.active })

  // Only close nav if it is open
  handleLinkClick = () => this.state.active && this.handleMenuToggle()

  handleScroll = e => {
    if (e === 'reduced') {
      this.setState({ reducedNavSize: true })
    } else {
      this.setState({ reducedNavSize: false })
    }
  }  

  render() {
    const { active, reducedNavSize } = this.state,      
      NavLink = ({ to, className, children, ...props }) => (
        <Link
          to={to}
          className={`NavLink ${
            to === this.state.currentPath ? 'active' : ''
          } ${className}`}
          onClick={this.handleLinkClick}
          {...props}
        >
          {children}
        </Link>
      )

    return (
      <Fragment>
        <Observer onChange={this.handleScroll}>
        <nav className={`Nav ${reducedNavSize ? 'Nav--reduced' : ''} ${active ? 'Nav-active' : ''}`} ref={this.ref}>
          <div className="Nav--Container container">
            <Link to="/" onClick={this.handleLinkClick}>
              <div
                className="Logo"
                style={{
                  backgroundImage: `url(/images/logo2.png)`
                }}
              />
            </Link>
            <div className="Nav--Links">
              <NavLink to="/">Home</NavLink>
              {/* <NavLink to="/about/">About</NavLink> */}
              <NavLink to="/services/">Services</NavLink>
              <NavLink to="/delivery-areas/">Delivery Areas</NavLink>
              <NavLink to="/news/">News</NavLink>
              <NavLink to="/faq/">FAQ</NavLink>
              <NavLink to="/get-in-touch/">Contact</NavLink>
            </div>

            <Link 
              to="/quick-quote/" 
              className={`Button Button-quickQuote ${
                '/quick-quote/' === this.state.currentPath 
                || '/quick-quote' === this.state.currentPath ? 'Button-quickQuote--hide' : ''
              }`}>
              Quick Quote
            </Link>

            <button
              className="Button-blank Nav--MenuButton"
              onClick={this.handleMenuToggle}
            >
              {active ? <X /> : <Menu />}
            </button>
          </div>
        </nav>
        </Observer>
      </Fragment>
    )
  }
}
// <NavLink to="/components/">Components</NavLink> <NavLink to="/default/">Default</NavLink> 
export default () => (
  <Location>{route => <Navigation {...route} />}</Location>
)
